import React from 'react';
import Header from './Components/Header';
import ODS11 from './Components/img/ODS11.png';
import ODS15 from './Components/img/ODS15.jpg';
import visor from './Components/img/visor1.jpg';
import Visor from './Visor';
import VisitorInfo from './Info';


const Acercade = () => {
    return (
        <>
            <Header />
            <VisitorInfo page="ACERCA DE"></VisitorInfo>
            <section className="container">
                <center><h3>¿Quiénes Somos?</h3></center>
                <hr />
                <div className="row mt-3">
                    <div className="col mt-2">Es una iniciativa peruana orientada a facilitar el registro, identificación, digitalización y visualización de árboles y bosques existentes en ciudades, bosques públicos y privados, territorios rurales, plantaciones, comunidades indígenas, zonas reservadas, etc.</div>
                </div>
                <div className="row mt-3">
                    <div className="col mt-2">IDENTIARBOL facilita herramientas informáticas para el registro y codificación de los árboles a nivel de cada individuo (o ejemplar). Incluyéndose datos taxonómicos, datos de ubicación (incluye georreferenciación), datos del crecimiento e incluso imágenes fotográficas de diversas partes del árbol, y a lo largo del tiempo. Además facilita el seguimiento y visualización de las especies a través de buscadores, fichas individuales, mapas de alta revolución y visores geográficos satelitales.</div>
                    <div className="col mt-2">IDENTIARBOL busca ser el más importante repositorio digital de arboles y bosques de Latinoamérica, orientado a dar soporte a la digitalización de los bosques, así como promover su cuidado, la apropiada identificación de especies, conservación, aprovechamiento, reforestación, trazabilidad, etc.</div>
                </div>
                <hr />
                <div className="row mt-3">
                    <div className="col align-self-center text-center">
                        <div className="card shadow">
                            <div className="card-body">
                                <p>La plataforma de IDENTIARBOL ofrece los siguientes servicios.</p>
                                <ul>
                                    <li>Registro digital de árboles de la ciudad.</li>
                                    <li>Registro digital y monitoreo de árboles de concesiones forestales.</li>
                                    <li>Registro digital y monitoreo de árboles de bosques de conservación.</li>
                                    <li>Registros digital y monitoreo de árboles y/o plantas de plantaciones y territorios agrícolas.</li>
                                    <li>Registro digital y monitoreo de árboles en bosques comunales, comunidades indígenas, huertos familiares y otros territorios.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <img src={visor} className="img-fluid" alt="" />
                    </div>
                </div>
                <hr />
                <div className="row mt-2">
                    <div className="col">Plataforma IDENTIARBOL está articulado a las acciones enmarcadas en el Objetivos del Desarrollo Sostenible de la ONU.</div>
                </div>
                <div className="row mt-3">
                    <div className="col align-self-center text-center">
                        <div className="card shadow">
                            <div className="card-body">
                                <p>Objetivo 11: Lograr que las ciudades sean más inclusivas, seguras, resilientes y sostenibles.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col"><img src={ODS11} className="img-fluid" alt="" /></div>
                    <div className="col align-self-center text-center">
                        <div className="card shadow">
                            <div className="card-body">
                                <p>Objetivo 15: Gestionar sosteniblemente los bosques, luchar contra la desertificación, detener e invertir la degradación de las tierras, detener la pérdida de biodiversidad.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col"><img src={ODS15} className="img-fluid" alt="" /></div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <p>IDENTIARBOL es un plataforma impulsada desde el Centro de Investigación, Innovación y Capacitación – Inventalo desde Iquitos Perú</p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Acercade;
