import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Polygon, Marker, LayersControl, Tooltip , Popup, Rectangle} from 'react-leaflet';
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import L, { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import Icono from './../Components/img/iconos/circle.svg';



import Control from 'react-leaflet-custom-control';

const center = {
  lat: -7.197625,
  lng: -76.791954,
};

const orangeIcon = new Icon({
    iconUrl: Icono, // URL de la imagen del ícono anaranjado
    iconSize: [10, 10], // Tamaño del ícono (ajústalo según tus necesidades)
  
    popupAnchor: [1, -34], // Punto de anclaje del popup
  });

const districtColors = [
  '#DFFFD9',
  '#AEE4AE',
  '#7ECC8E',
  '#57D957',
  '#30C030',
  '#0A7B0D',
];

const apiUrl = process.env.REACT_APP_API_URL;
const debugMode = process.env.REACT_APP_DEBUG_MODE === 'true';



const Visorunico = ({year}) => {
  /* ESTILOS DEL MAPA */
  const googleApiKey = "AIzaSyA68xOsLic_QKxD4EcnwZDrtv-iE09-95M"; // Reemplaza con tu clave de API de Google Maps
  const mapStyles = {
    Aubergine: require("./../Stylemaps/aubergine-map-style.json"),
    Dark: require("./../Stylemaps/dark-map-style.json"),
    Retro: require("./../Stylemaps/retro-map-style.json"),
    Night: require("./../Stylemaps/night-map-style.json"),
    Estandar: require("./../Stylemaps/standard-map-style.json"),
  };

  const [selectedStyle, setSelectedStyle] = useState("Aubergine");

  const handleStyleChange = (newStyle) => {
    setSelectedStyle(newStyle);
  };
  /* ESTILOS DEL MAPA */

  /* COORDENADAS */
  const initialState = {
    isCollapseOpen: false,


  };

  const [coordinates, setCoordinates] = useState([]);
  const [selectedInfo, setSelectedInfo] = useState(null);

  useEffect(() => {
    // Realizar una solicitud a la API para obtener los datos de las coordenadas
    axios.get(apiUrl+'user/indexbinidentiarbol')
      .then(response => {
        // Extraer los datos de la respuesta
        const binnacleData = response.data.binnacle;

        // Filtrar y mapear las coordenadas desde los datos
        const coords = binnacleData
          .filter(item => item.bin_txt_lat && item.bin_txt_lon) // Filtrar elementos con coordenadas válidas
          .map(item => ({
            lat: parseFloat(item.bin_txt_lat),
            lng: parseFloat(item.bin_txt_lon),
            bin_int_id: item.bin_int_id,
            bin_txt_ip: item.bin_txt_ip,
            bin_txt_country: item.bin_txt_country,
            bin_txt_regionname: item.bin_txt_regionname,
          }));

        setCoordinates(coords);
      })
      .catch(error => {
        console.error('Error al obtener datos de coordenadas', error);
      });
  }, []);

  const handleMarkerClick = (info) => {
    setSelectedInfo(info);
  };




  return (
    <>


          <MapContainer center={center} zoom={4} style={{ width: '100%', height: '750px' }}>




            <LayersControl position="topright">
              {Object.keys(mapStyles).map((style) => (
                <LayersControl.BaseLayer
                  key={style}
                  checked={style === selectedStyle}
                  name={style + " Map"}
                >
                  <ReactLeafletGoogleLayer
                    apiKey={googleApiKey}
                    type="roadmap"
                    styles={mapStyles[style]}
                  />
                </LayersControl.BaseLayer>
              ))}
            </LayersControl>

            {coordinates.map(coord => (
        <Marker
          key={coord.bin_int_id}
          position={[coord.lat, coord.lng]}
          eventHandlers={{
            click: () => handleMarkerClick(coord)
          }}
          icon={ orangeIcon  }
        >
          <Popup>
            <strong>Coordenadas:</strong> {coord.lat}, {coord.lng}
          </Popup>
        </Marker>
      ))}

      {selectedInfo && (
        <Popup position={[selectedInfo.lat, selectedInfo.lng]} onClose={() => setSelectedInfo(null)}>
          <div>
            <strong>IP:</strong> {selectedInfo.bin_txt_ip}<br />
            <strong>País:</strong> {selectedInfo.bin_txt_country}<br />
            <strong>Región:</strong> {selectedInfo.bin_txt_regionname}<br />
          </div>
        </Popup>
      )}









      

          </MapContainer>

    </>
  );
}

export default Visorunico;
