import React from 'react';
import Header from './Components/Header';

const Cantidadcolegios = () => {
    return (
        <>
            <Header />
        </>

    );
};

export default Cantidadcolegios;
