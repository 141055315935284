import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import { Select, MenuItem, FormControl, InputLabel, Box } from '@mui/material';

const apiUrl = process.env.REACT_APP_API_URL;

const openImageInNewTab = (data) => {
    const regIntId = data.use_txt_dni;
    window.open(`/albums/${regIntId}`, '_blank');
};

const Ranking = () => {
    const tableRef = useRef(null);
    const [rankingData, setRankingData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [locationFilter, setLocationFilter] = useState('');
    const [yearFilter, setYearFilter] = useState('');

    // Fetch data from the API
    useEffect(() => {
        fetch(`${apiUrl}user/ranking`)
            .then((response) => response.json())
            .then((data) => {
                const numberedData = data.ranking.map((item, index) => ({
                    ...item,
                    'N°': index + 1,
                }));
                setRankingData(numberedData);
                setFilteredData(numberedData); // Initialize with all data
            })
            .catch((error) => console.error('Error fetching ranking data:', error));
    }, []);

    useEffect(() => {
        if ($.fn.dataTable.isDataTable(tableRef.current)) {
            $(tableRef.current).DataTable().destroy();
        }

        const dataTable = $(tableRef.current).DataTable({
            data: filteredData,
            columns: [
                { data: 'N°', title: 'N°' },
                { data: 'use_txt_name', title: 'Nombre' },
                { data: 'use_txt_lastname', title: 'Apellido' },
                { data: 'use_txt_dni', title: 'DNI' },
                { data: 'use_txt_school', title: 'Escuela' },
                { data: 'col_txt_ubigeo', title: 'Ubigeo' },
                { data: 'conteo_2023', title: 'Conteo 2023' },
                { data: 'conteo_2024', title: 'Conteo 2024' },
                { data: 'conteo_total', title: 'Total Registros' },
                {
                    data: null,
                    title: 'Ver Álbum',
                    render: function (data, type, row) {
                        return `<button class="ver-button" data-dni="${row.use_txt_dni}"> Ver </button>`;
                    },
                },
            ],
            pageLength: 40,
            destroy: true,
        });

        $(tableRef.current).on('click', '.ver-button', function () {
            const dni = $(this).data('dni');
            openImageInNewTab({ use_txt_dni: dni });
        });

        return () => {
            $(tableRef.current).off('click', '.ver-button');
            dataTable.destroy();
        };
    }, [filteredData]);

    const handleFilterChange = () => {
        const filtered = rankingData.filter((item) => {
            // Filtrar por año
            const matchesYear =
                yearFilter === '2023'
                    ? item.conteo_2023 > 0
                    : yearFilter === '2024'
                    ? item.conteo_2024 > 0
                    : true;

            // Filtrar por ubicación/ciudad
            const matchesLocation =
                locationFilter === '160103'
                    ? item.col_txt_ubigeo === '160103'
                    : locationFilter === '160101'
                    ? item.col_txt_ubigeo === '160101'
                    : true;

            return matchesYear && matchesLocation;
        });

        // Recalcular la numeración de las filas
        const recalculatedData = filtered.map((item, index) => ({
            ...item,
            'N°': index + 1,
        }));

        setFilteredData(recalculatedData);
    };

    useEffect(() => {
        handleFilterChange();
    }, [locationFilter, yearFilter]);

    return (
        <div>
            <h1 style={{ marginBottom: '50px' }}>Vista de Ranking</h1>
            <Box display="flex" gap={2} marginBottom={3}>
                <FormControl>
                    <InputLabel>Ciudad</InputLabel>
                    <Select
                        value={locationFilter}
                        onChange={(e) => setLocationFilter(e.target.value)}
                        label="Ubigeo"
                        style={{ width: '150px' }}
                    >
                        <MenuItem value="">Todas las ciudades</MenuItem>
                        <MenuItem value="160103">Nauta</MenuItem>
                        <MenuItem value="160101">Iquitos</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel>Año</InputLabel>
                    <Select
                        value={yearFilter}
                        onChange={(e) => setYearFilter(e.target.value)}
                        label="Año"
                        style={{ width: '150px' }}
                    >
                        <MenuItem value="">Todos los años</MenuItem>
                        <MenuItem value="2023">2023</MenuItem>
                        <MenuItem value="2024">2024</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <table ref={tableRef} className="display" style={{ width: '100%' }} />
        </div>
    );
};

export default Ranking;
