import React, { useState } from 'react';
import './Source/style.css';
import Header from './Components/Header';
import { useAuth } from './Components/Authentication';
import { useNavigate } from 'react-router-dom'; // <-- Importa useNavigate
import VisitorInfo from './Info';

function VistaLogin() {
    const { login } = useAuth();
    const navigate = useNavigate(); // <-- Crea una instancia de navigate

    // Establecer estados locales para el email y la contraseña
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(""); // Para mostrar mensajes de error

    const handleLogin = (e) => {
      e.preventDefault();
      if (login(email, password)) {
        // Redirigir al dashboard
        navigate("/main-dashboard"); // <-- Redirecciona aquí
      } else {
        setErrorMessage("Credenciales inválidas");
      }
    }

    return (
        <>
            <Header />
            <VisitorInfo page="LOGIN"></VisitorInfo>

            <div className="login-container">
            <div className="login-box">
                <h2>Iniciar sesión</h2>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <form onSubmit={handleLogin}>
                    <div className="form-group">
                        <label>Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Contraseña:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit">Ingresar</button>
                </form>
            </div>
            </div>
        </>
    );
}

export default VistaLogin;
