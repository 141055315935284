import React, { useState, useRef } from "react";
import Swal from "sweetalert2";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import yapelogo from "./Components/yapelogo.png";
import plinlogo from "./Components/plinlogo.png";
import transferencialogo from "./Components/visa-logo.jpg";
import LOGO2 from "./Components/LOGO2.png";
import withReactContent from "sweetalert2-react-content";
import Header from "./Components/Header";

const apiDonnors = process.env.REACT_APP_API_DONNORS;
const MySwal = withReactContent(Swal);

const Donaciones = () => {
  const [nameDonor, setNameDonor] = useState("");
  const [emailDonor, setEmailDonor] = useState("");
  const [phoneDonor, setPhoneDonor] = useState("");
  const [message, setMessage] = useState("");
  const fileInputRef = useRef(null);

  const sendImage = async (e) => {
    e.preventDefault(); // Previene el comportamiento por defecto del formulario

    const file = fileInputRef.current.files[0];
    if (!file) {
      MySwal.fire("Error", "Selecciona un archivo antes de enviar.", "error");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name_donor", nameDonor);
      formData.append("email_donor", emailDonor);
      formData.append("phone_donor", phoneDonor);
      formData.append("message_donor", message);
      formData.append("pay_url_voucher", file);
      formData.append("ser_int_id", "2"); // Asumiendo que '2' es un valor fijo para este campo

      const response = await fetch(
        apiDonnors+`payment/donation`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        MySwal.fire("Éxito", "Donación registrada con éxito.", "success");
      } else {
        MySwal.fire(
          "Error al enviar",
          "Hubo un problema al registrar la donación.",
          "error"
        );
      }
    } catch (error) {
      console.error("Error:", error);
      MySwal.fire("Error", "Hubo un error al procesar la solicitud.", "error");
    }
  };

  return (
    <div>
      <Header />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            width: "50%",
            backgroundColor: "#4caf50",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "1000px",
            position: "relative",
          }}
        >
          <img
            src={LOGO2}
            alt="logo-2-descriptivo-iden"
            style={{
              width: "50%",
              height: "auto",
              position: "absolute",
              top: 200,
            }}
          />
          <div
            style={{
              position: "absolute",
              top: 90,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              fontWeight: "bold",
            }}
          >
            <h1 style={{ fontSize: "50px" }}>IdentiArbol</h1>
          </div>
        </div>

        <div style={{ width: "70%", padding: "30px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div style={{ width: "50%" }}>
              <Row>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column", // Asegura que los hijos se apilen verticalmente
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={yapelogo}
                    alt="logo-descriptivo"
                    style={{ width: "100%", height: "auto" }}
                  />
                  <p>964203333</p>
                </Col>

                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column", // Asegura que los hijos se apilen verticalmente
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={plinlogo}
                    alt="logo-descriptivo"
                    style={{ width: "100%", height: "auto" }}
                  />
                  <p>964203333</p>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={transferencialogo}
                    alt="logo3-descriptivo"
                    style={{
                      width: "100%",
                      height: "auto",
                      paddingBottom: "12px",
                    }}
                  />
                  <Button
                    style={{ backgroundColor: "blue", height: "40px" }}
                    onClick={() => {
                      MySwal.fire({
                        title: "Donar - Cuenta",
                        html: `
                               <div style="white-space: pre-line;">
                               Cuenta Bancaria Empresa en Interbank\n
                               Cuenta Corriente Soles: 740-3005638698.\n
                               Cuenta Interbancaria (CCI): 003-740-003005638698-87\n
                               Nombre: Tecnologías Informáticas invéntalo
                               </div>
                              `,
                      });
                    }}
                  >
                    Donar
                  </Button>
                </Col>
              </Row>

              {/* Entradas de texto para datos personales */}
              <div class="card-body">
                <Form onSubmit={sendImage}>
                  <div className="card mt-3">
                    <div className="card-header text-center">
                      Registra tu donación
                    </div>

                    <div className="card-body">
                      <div className="mb-3" style={{ width: "70%" }}>
                        <Form.Label htmlFor="formNombre">Nombre</Form.Label>
                        <Form.Control
                          type="text"
                          id="formNombre"
                          placeholder="Ingresa tu nombre"
                          aria-label="Nombre"
                          value={nameDonor}
                          onChange={(e) => setNameDonor(e.target.value)}
                        />
                      </div>

                      <div className="mb-3" style={{ width: "70%" }}>
                        <Form.Label htmlFor="formEmail">Email</Form.Label>
                        <Form.Control
                          type="email"
                          id="formEmail"
                          placeholder="Ingresa tu email"
                          aria-label="Email"
                          value={emailDonor}
                          onChange={(e) => setEmailDonor(e.target.value)}
                        />
                      </div>

                      <div className="mb-3" style={{ width: "70%" }}>
                        <Form.Label htmlFor="formTelefono">Teléfono</Form.Label>
                        <Form.Control
                          type="text"
                          id="formTelefono"
                          placeholder="Ingresa tu teléfono"
                          aria-label="Teléfono"
                          value={phoneDonor}
                          onChange={(e) => setPhoneDonor(e.target.value)}
                        />
                      </div>

                      <div className="mb-3" style={{ width: "70%" }}>
                        <Form.Label htmlFor="formMensaje">Mensaje</Form.Label>
                        <Form.Control
                          type="text"
                          id="formMensaje"
                          placeholder="Deja tu mensaje"
                          aria-label="Mensaje"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                      </div>

                      <div className="mb-3" style={{ width: "70%" }}>
                        <Form.Label htmlFor="formArchivo">
                          Subir su comprobante de donación
                        </Form.Label>
                        <Form.Control
                          type="file"
                          id="formArchivo"
                          aria-label="Archivo"
                          ref={fileInputRef}
                        />
                      </div>
                    </div>
                  </div>

                  <div style={{ paddingTop: "20px" }}>
                    <Button
                      type="submit"
                      className="btn btn- "
                      style={{ backgroundColor: "green  " }}
                    >
                      Registrar
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Donaciones;
