import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Components/Header';
import Banner from './Components/Banner.jpg';
import axios from 'axios';
import VisitorInfo from './Info';
import loadingGif from './Source/loader.gif'; // Importa el GIF de carga
import donationGif from './Source/identicharacter.gif'; // GIF de personaje para el modal
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy, faVideo } from "@fortawesome/free-solid-svg-icons";


const apiUrl = process.env.REACT_APP_API_URL;

const Inicio = () => {
    const [data, setData] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [showModal, setShowModal] = useState(true); // Estado para mostrar u ocultar el modal
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.get(apiUrl + 'frontend');
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, []);

    // Mostrar el GIF de carga si aún no se ha recibido la data
    if (!data) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <img src={loadingGif} alt="Cargando..." style={{ width: '200px' }} />
            </div>
        );
    }

    const handleBoxClick = (route) => {
        navigate(route);
    };

    const handleespecial=(route)=>{
        navigate(route);
    }

    const handleSearch = () => {
        navigate(`/buscador-especie?query=${searchTerm}`);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleDonateClick = () => {
        navigate('/donaciones'); // Redirige a la página de donaciones
        setShowModal(false);
    };

    return (
        <>
            <VisitorInfo page="inicio" />
            <Header />
            {/* Modal */}
{/*             {showModal && (
                <div 
                    className="modal fade show d-block" 
                    tabIndex="-1" 
                    role="dialog" 
                    onClick={handleCloseModal}
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                >
                    <div 
                        className="modal-dialog modal-dialog-centered" 
                        role="document"
                        onClick={(e) => e.stopPropagation()} // Prevenir cierre al hacer clic dentro del modal
                    >
                        <div className="modal-content">
                            <div className="modal-header" style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', color: 'green'}}>
                                <h5 className="modal-title">¡Haz una donación!</h5>
                                <button 
                                    type="button" 
                                    className="close" 
                                    onClick={handleCloseModal}
                                    style={{
                                        backgroundColor: 'red',
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '50%',
                                        width: '30px',
                                        height: '30px',
                                        fontSize: '1.2rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body text-center">
                                <center>
                                    <img src={donationGif} alt="Haz una donación" className="mb-3" style={{ width: '150px' }} />
                                </center>
                                <p>¡Apóyanos con tu donación para seguir, informando y cuidando el medio ambiente!</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" style={{ backgroundColor: 'green' }} className="btn btn-primary" onClick={handleDonateClick}>
                                    Ir a donar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )} */}


            <div>
                <img src={Banner} alt="Banner Descriptivo" className="w-100" />
            </div>
            <div className="p-4">
                {/* Barra de búsqueda */}
             
                   <div className="d-flex justify-content-center">
                    {/* Grupo de entrada de búsqueda */}
                    <div className="input-group w-50 mr-3" style={{ maxWidth: '700px' }}>
                        <input
                        type="text"
                        placeholder="Buscar"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="form-control"
                        />
                        <div className="input-group-append">
                        <button
                            onClick={handleSearch}
                            className="btn btn-success"
                        >
                            Buscar
                        </button>
                        </div>
                    </div>

                    {/* Botón de enlace a Google Play */}
                    <a
                        href="https://play.google.com/store/apps/details?id=com.inventalo.identiarbolapp.identiarbol&hl=es_PE"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-outline-secondary d-flex align-items-center"
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Google_Play_Arrow_logo.svg/512px-Google_Play_Arrow_logo.svg.png"
                        alt="Google Play"
                        style={{ width: '20px', height: '20px', marginRight: '8px' }}
                        />
                        Descarga Identiarbol desde <strong style={{ marginLeft: '5px' }}>Google Play</strong>
                    </a>
                    </div>
       

                {/* Cajas de información */}
                <div className="d-flex justify-content-center flex-wrap">
                    {[
                        { label: "REGISTROS FOTOGRÁFICOS", value: data.images[0].cantidad, route: "/galeria" },
                        { label: "Árboles registrados", value: data.trees[0].cantidad, route: "/arboles" },
                        { label: "Cantidad de participantes", value: data.users[0].cantidad, route: "/participantes" },
                    ].map((item, index) => (
                        <div
                            key={index}
                            className="card text-center m-2"
                            style={{ width: '16rem', cursor: 'pointer' }}
                            onClick={() => handleBoxClick(item.route)}
                        >
                            <div className="card-body">
                                <h5 className="card-title">{item.label}</h5>
                                <p className="card-text">{item.value}</p>
                            </div>
                        </div>
                    ))}

                    {/* Primer botón: Participa y Gana */}
                    <div
                            key={2321}
                            className="card text-center m-2 border border-success"
                            style={{ width: "16rem", cursor: "pointer" }}
                            onClick={() => handleespecial("/bases")}
                        >
                            <div className="card-body d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faTrophy} size="2x" color="green" />
                            <h5 className="card-title mt-3" style={{ color: "green" }}>
                                Participa y Gana!
                            </h5>
                            </div>
                        </div>

                        {/* Segundo botón: Ver video del concurso */}
                        <a href="https://www.youtube.com/watch?v=6IhTd7IYhWk" target='_blank'>
                        <div
                            key={2322}
                            className="card text-center m-2 border border-success"
                            style={{ width: "16rem", cursor: "pointer" }}
                            
                        >
                            <div className="card-body d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faVideo} size="2x" color="green" />
                            <h5 className="card-title mt-3" style={{ color: "green" }}>
                                Ver video del concurso
                            </h5>
                            </div>
                        </div>
                        </a>
                </div>
            </div>
        </>
    );
};

export default Inicio;
