import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { MapContainer, LayersControl, CircleMarker } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import "leaflet/dist/leaflet.css";
import { Button, Col, Container, Row, Modal } from "react-bootstrap";
import "flowbite/dist/flowbite.css";
import Select from "react-select";

const apiUrl = process.env.REACT_APP_API_URL;
const baseURL = "https://identiarbol.org/servicesbackend/public/";

const options = [
  { value: "Palmera", label: "Palmera" },
  { value: "Maderable", label: "Maderable" },
  { value: "Frutal", label: "Frutal" },
  { value: "Medicinal", label: "Medicinal" },
  { value: "Ornamental", label: "Ornamental" },
  // Agrega más opciones según sea necesario
];

const EditorAlbum = () => {
  const [treeDetails, setTreeDetails] = useState({});
  const [images, setImages] = useState([]);
  const [hoveredImage, setHoveredImage] = useState(null);
  const { regIntId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [editedCommonName, setEditedCommonName] = useState(
    treeDetails.reg_txt_commonnames || ""
  );
  const [editedScientificName, setEditedScientificName] = useState(
    treeDetails.reg_txt_scientificname || ""
  );
  const [editedreg_txt_typetree, setEditedreg_txt_typetree] = React.useState(
    treeDetails.reg_txt_typetree || []
  );
  const [editedreg_txt_typetree2, setEditedreg_txt_typetree2] = useState(
    treeDetails.reg_txt_typetree || []
  );
  const [editedreg_txt_totalheight, setEditedreg_txt_totalheight] = useState(
    treeDetails.reg_txt_totalheight || ""
  );
  const [editedreg_txt_dpa, setEditedreg_txt_dpa] = useState(
    treeDetails.reg_txt_dpa || ""
  );

  const [editedreg_txt_statusreview, setEditedreg_txt_statusreview] = useState(
    treeDetails.reg_txt_statusreview || ""
  );
  const handleChange = (selected) => {
    setEditedreg_txt_typetree(selected);
    setEditedreg_txt_typetree2(
      selected.map((option) => option.label).join(", ")
    );
  };
  const apiUrlImg = process.env.REACT_APP_URL_IMG;
  const [selectedStyle, setSelectedStyle] = useState("Aubergine");

  const googleApiKey = "AIzaSyCG90SDbD-bJ4dMB6OtebSbAeRs5haolq0";
  const mapStyles = {
    Aubergine: require("../Stylemaps/aubergine-map-style.json"),
    Dark: require("../Stylemaps/dark-map-style.json"),
    Retro: require("../Stylemaps/retro-map-style.json"),
    Night: require("../Stylemaps/night-map-style.json"),
    Estandar: require("../Stylemaps/standard-map-style.json"),
  };

  const handleEditTreeData = () => {
    // Abre el modal
    setShowModal(true);
  };

  const handleCloseModal = () => {
    // Cierra el modal
    setShowModal(false);
  };
  const fetchTreeDetails = async () => {
    try {
      const response = await axios.get(`${apiUrl}treedetails/${regIntId}`);
      setTreeDetails(response.data.imagesTree[0]);

      // Asigna los valores a los estados de edición
      setEditedCommonName(
        response.data.imagesTree[0].reg_txt_commonnames || ""
      );
      setEditedScientificName(
        response.data.imagesTree[0].reg_txt_scientificname || ""
      );
      setEditedreg_txt_typetree(
        response.data.imagesTree[0].reg_txt_typetree || []
      );
      setEditedreg_txt_totalheight(
        response.data.imagesTree[0].reg_txt_totalheight || ""
      );
      setEditedreg_txt_dpa(response.data.imagesTree[0].reg_txt_dpa || "");
      setEditedreg_txt_statusreview(
        response.data.imagesTree[0].reg_txt_statusreview || ""
      );

      const imageResponse = await axios.get(`${apiUrl}tree/${regIntId}`);
      setImages(imageResponse.data.tree);
    } catch (error) {
      console.error("Error fetching tree details:", error);
    }
  };

  useEffect(() => {
    fetchTreeDetails();
  }, [regIntId]);

  useEffect(() => {
    fetchTreeDetails();
  }, [regIntId]);

  const handleMouseOver = (image) => {
    setHoveredImage(image);
  };

  const handleMouseOut = () => {
    setHoveredImage(null);
  };

  const handleSaveChanges = async () => {
    try {
      // Mapea los estados de revisión a los valores requeridos
      const statusMapping = {
        revisado: "R",
        noRevisado: "N",
        observado: "O",
      };

      // Construye el objeto con los datos actualizados
      const updatedData = {
        reg_int_id: regIntId,
        reg_txt_scientificname: editedScientificName,
        reg_txt_commonnames: editedCommonName,
        reg_txt_dpa: editedreg_txt_dpa,
        reg_txt_totalheight: editedreg_txt_totalheight,
        reg_txt_typetree: editedreg_txt_typetree2,
        reg_txt_statusreview: editedreg_txt_statusreview || "N", // Por defecto, establece "N" si no se encuentra coincidencia
      };
      console.log(updatedData);

      // Realiza la solicitud HTTP para actualizar el árbol
      const response = await axios.post(
        "http://108.181.166.127/servicesbackend/public/identiarbol/updatetree",
        updatedData
      );

  
      console.log("Respuesta de la API:", response.data);

     
      handleCloseModal();
    } catch (error) {
      console.error("Error al actualizar el árbol:", error);
     
    }

    fetchTreeDetails();
  };

  return (
    <div>
      <Container>
        <Row className="d-flex align-items-center justify-content-center">
          <Col xs={4}>
            <br />
            <h1>
              <strong>Datos del Usuario: </strong>
            </h1>
            <div
              className="p-2 border rounded bg-light"
              style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
            >
              <div>
                <label>
                  <strong>Nombres:</strong>
                </label>
                <span>
                  {treeDetails.use_txt_name &&
                    treeDetails.use_txt_name.toUpperCase()}
                </span>
              </div>
              <div>
                <label>
                  <strong>Apellidos:</strong>
                </label>
                <span>
                  {treeDetails.use_txt_lastname &&
                    treeDetails.use_txt_lastname.toUpperCase()}
                </span>
              </div>
            </div>
            <br />
            <h2>
              <strong>Datos del Árbol:</strong>
            </h2>
            <div
              className="p-2 border rounded bg-light"
              style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
            >
              <div>
                <div>
                  <label>
                    <strong>Nombre Comun:</strong>
                  </label>
                  <span>
                    {treeDetails.reg_txt_commonnames &&
                      treeDetails.reg_txt_commonnames.toUpperCase()}
                  </span>
                </div>
                <div>
                  <label>
                    <strong>Nombre Científico:</strong>
                  </label>
                  <span>
                    {treeDetails.reg_txt_scientificname &&
                      treeDetails.reg_txt_scientificname.toUpperCase()}
                  </span>
                </div>
                <div>
                  <label>
                    <strong>Tipo de especie:</strong>
                  </label>
                  <span>
                    {treeDetails.reg_txt_typetree
                      ? treeDetails.reg_txt_typetree.toUpperCase()
                      : "Sin asignar"}
                  </span>
                </div>

                <div>
                  <label>
                    <strong>Altura:</strong>
                  </label>
                  <span>
                    {treeDetails.reg_txt_totalheight
                      ? treeDetails.reg_txt_totalheight.toLowerCase()
                      : "Sin asignar"}
                  </span>
                </div>
                <div>
                  <label>
                    <strong>DPA:</strong>
                  </label>
                  <span>
                    {treeDetails.reg_txt_dpa
                      ? treeDetails.reg_txt_dpa.toLowerCase()
                      : "Sin asignar"}
                  </span>
                </div>
                <div>
                  <label>
                    <strong>Estado de revisión:</strong>
                  </label>
                  <span>
                    {treeDetails.reg_txt_statusreview
                      ? treeDetails.reg_txt_statusreview.toLowerCase()
                      : "No revisado"}
                  </span>
                </div>
              </div>
            </div>
            <br />
            <button
              style={{
                padding: "10px",
                backgroundColor: "#4CAF50", // Color de fondo verde, puedes ajustar según tu preferencia
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              }}
              onClick={handleEditTreeData}
            >
              Editar Valores del Arbol
            </button>
            {/* Modal */}
            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Editar Datos del Árbol</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <label>
                    <strong>Nombre Comun:</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={editedCommonName}
                    onChange={(e) => setEditedCommonName(e.target.value)}
                  />
                </div>
                <div>
                  <label>
                    <strong>Nombre Científico:</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={editedScientificName}
                    onChange={(e) => setEditedScientificName(e.target.value)}
                  />
                </div>

                <div>
                  <label>
                    <strong>Tipo de especie:</strong>
                  </label>
                  <Select
                    options={options}
                    isMulti
                    value={editedreg_txt_typetree}
                    onChange={handleChange}
                  />
                  <br />
                  <input
                    type="text"
                    value={
                      Array.isArray(editedreg_txt_typetree)
                        ? editedreg_txt_typetree
                            .map((option) => option.label)
                            .join(", ")
                        : ""
                    }
                    readOnly
                  />
                </div>

                <div>
                  <label>
                    <strong>Altura:</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={editedreg_txt_totalheight}
                    onChange={(e) =>
                      setEditedreg_txt_totalheight(e.target.value)
                    }
                  />
                </div>
                <div>
                  <label>
                    <strong>DPA:</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={editedreg_txt_dpa}
                    onChange={(e) => setEditedreg_txt_dpa(e.target.value)}
                  />
                </div>
                <div>
                  <label>
                    <strong>Revisión:</strong>
                  </label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      checked={editedreg_txt_statusreview === "R"}
                      onChange={() => setEditedreg_txt_statusreview("R")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Revisado
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      checked={editedreg_txt_statusreview === "N"}
                      onChange={() => setEditedreg_txt_statusreview("N")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault2"
                    >
                      No revisado
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault3"
                      checked={editedreg_txt_statusreview === "O"}
                      onChange={() => setEditedreg_txt_statusreview("O")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault3"
                    >
                      Observación
                    </label>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Cerrar
                </Button>
                <Button variant="primary" onClick={handleSaveChanges}>
                  Guardar Cambios
                </Button>
              </Modal.Footer>
            </Modal>
          </Col>
          <Col xs={8}>
            <div>
              <br />
              <h4>
                <b>Ubicación del Árbol:</b>
              </h4>
              <div style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}>
                {treeDetails.reg_txt_lat && treeDetails.reg_txt_long && (
                  <MapContainer
                    center={[treeDetails.reg_txt_lat, treeDetails.reg_txt_long]}
                    zoom={13}
                    style={{ height: "325px", width: "100%" }}
                  >
                    <LayersControl position="topright">
                      {Object.keys(mapStyles).map((style) => (
                        <LayersControl.BaseLayer
                          key={style}
                          checked={style === selectedStyle}
                          name={style + " Map"}
                        >
                          <ReactLeafletGoogleLayer
                            apiKey={googleApiKey}
                            type="roadmap"
                            styles={mapStyles[style]}
                          />
                        </LayersControl.BaseLayer>
                      ))}
                    </LayersControl>
                    <CircleMarker
                      center={[
                        treeDetails.reg_txt_lat,
                        treeDetails.reg_txt_long,
                      ]}
                      radius={5}
                      fillColor="black"
                      color="yellow"
                      weight={0.4}
                      fillOpacity={5}
                    />
                  </MapContainer>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <div>
          <h2>Imágenes</h2>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
            }}
          >
            {images &&
              images.map((image, index) => (
                <div
                  key={index}
                  className="image-item"
                  style={{ margin: "5px" }}
                >
                  <img
                    src={`${
                      image.ima_txt_urlimages.includes("drive.google.com")
                        ? `https://lh3.googleusercontent.com/d/${
                            image.ima_txt_urlimages.split("/")[5]
                          }`
                        : apiUrlImg+image.ima_txt_urlimages
                    }`}
                    alt={`Imagen ${index + 1}`}
                    style={{
                      width: "250px",
                      height: "400px",
                      position: "relative",
                      overflow: "hidden",
                    }}
                    loading="lazy"
                    onMouseOver={() => handleMouseOver(image)}
                    onMouseOut={handleMouseOut}
                  />
                  {hoveredImage === image && (
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        background: "rgba(0,0,0,0.5)",
                        color: "white",
                        padding: "10px",
                        borderRadius: "5px",
                        transition: "opacity 0.5s, transform 1.0s",
                        transform:
                          hoveredImage === image
                            ? "translateY(0)"
                            : "translateY(100%)",
                        opacity: hoveredImage === image ? 1 : 0,
                      }}
                    >
                      <p>Parte: {image.ima_txt_part}</p>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default EditorAlbum;
