import React, { useEffect, useRef, useState } from 'react';
import Header from './Components/Header';
import VisitorInfo from './Info';
import Chart from 'react-google-charts';

const apiUrl = process.env.REACT_APP_API_URL;
const debugMode = process.env.REACT_APP_DEBUG_MODE === 'true';

const Estadisticas = () => {
    const [districtChartData, setDistrictChartData] = useState([]);
    const [schoolChartData, setSchoolChartData] = useState([]);
    const [treeChartData, setTreeChartData] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [totalTrees, setTotalTrees] = useState(0);
    const tableRef = useRef(null);
    const tableHeaderStyle = {
        border: '1px solid #ddd',
        padding: '8px',
        textAlign: 'left',
        backgroundColor: '#f2f2f2',
    };

    const tableCellStyle = {
        border: '1px solid #ddd',
        padding: '8px',
        textAlign: 'left',
    };

    useEffect(() => {
        // Realizar la solicitud a la API para usuarios por distrito
        fetch(apiUrl + 'user/userdistrict')
            .then(response => response.json())
            .then(data => {
                const districtCounts = {};
                let total = 0;

                data.users.forEach(entry => {
                    const district = entry.use_txt_district;
                    districtCounts[district] = (districtCounts[district] || 0) + 1;
                    total += 1;
                });

                const newDistrictData = [['Category', 'Usuarios']];
                Object.entries(districtCounts).forEach(([district, count]) => {
                    newDistrictData.push([district, count]);
                });

                setDistrictChartData(newDistrictData);
                setTotalUsers(total);
            })
            .catch(error => {
                console.error('Error fetching data from API (District):', error);
            });

        // Realizar la solicitud a la API para usuarios por colegio
        fetch(apiUrl + 'user/userschool')
            .then(response => response.json())
            .then(data => {
                const schoolCounts = {};
                let uniqueSchools = new Set();
                data.users.forEach(entry => {
                    const school = entry.use_txt_school;
                    const count = 1;
                    schoolCounts[school] = (schoolCounts[school] || 0) + 1;
                    uniqueSchools.add(school);
                });

                const newSchoolData = [['Category', 'Usuarios']];
                Object.entries(schoolCounts)
                    .sort(([, a], [, b]) => b - a)
                    .forEach(([school, count]) => {
                        newSchoolData.push([school, count]);
                    });

                setSchoolChartData(newSchoolData);
            })
            .catch(error => {
                console.error('Error fetching data from API (School):', error);
            });

        // Realizar la solicitud a la API para árboles por distrito
        fetch(apiUrl + 'user/treedistrict')
        .then(response => response.json())
        .then(data => {
            const treeCounts = {};
            let total = 0;

            data.users.forEach(entry => {
                const tree = entry.use_txt_district;
                treeCounts[tree] = (treeCounts[tree] || 0) + 1;
                total += 1;
            });

            const newTreeData = [['Category', 'Usuarios']];
            Object.entries(treeCounts).forEach(([tree, count]) => {
                newTreeData.push([tree, count]);
            });

            setTreeChartData(newTreeData);
            setTotalTrees(total);
        })
        .catch(error => {
            console.error('Error fetching data from API (District):', error);
        });
        
    }, []);

    return (
        <div>
            <Header />
            <VisitorInfo page="Ranking"></VisitorInfo>

            <div className='p-3'>
                <h1 style={{ fontSize: 25 }}><b>Estadísticas</b></h1>

                {/* Gráfico de Pastel para Usuarios por Distrito */}
                <div style={{ marginTop: '20px', display: 'flex', alignItems: 'flex-center' }}>
                    <Chart
                        width={'100%'}
                        height={'400px'}
                        chartType="PieChart"
                        loader={<div>Cargando Diagrama...</div>}
                        data={districtChartData}
                        options={{
                            title: 'Usuarios por distritos',
                        }}
                        rootProps={{ 'data-testid': '1' }}
                    />

                    <table style={{ borderCollapse: 'collapse', width: '50%', height: '300px' }}>
                        <thead>
                            <tr>
                                <th style={tableHeaderStyle}>Distrito</th>
                                <th style={tableHeaderStyle}>Usuarios</th>
                            </tr>
                        </thead>
                        <tbody>
                            {districtChartData.slice(1).map(([district, count]) => (
                                <tr key={district}>
                                    <td style={tableCellStyle}>{district}</td>
                                    <td style={tableCellStyle}>{count}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <p>Total Usuarios: {totalUsers}</p>

                {/* Gráfico de Barras para Usuarios por Colegio */}
                <Chart
                    width={'100%'}
                    height={'800px'}
                    chartType="BarChart"
                    loader={<div>Cargando Diagrama.....</div>}
                    data={schoolChartData}
                    options={{
                        title: 'Usuarios por colegios',
                        hAxis: { title: 'Cantidad de usuarios' },
                        vAxis: {
                            title: 'Colegios',
                            textStyle: { fontSize: 10 }
                        },
                    }}
                    rootProps={{ 'data-testid': '2' }}
                />

                {/* Gráfico de Pastel para Árboles por Distrito */}
                <div style={{ marginTop: '20px', display: 'flex', alignItems: 'flex-center' }}>
                    <Chart
                        width={'100%'}
                        height={'400px'}
                        chartType="PieChart"
                        loader={<div>Cargando Diagrama...</div>}
                        data={treeChartData}
                        options={{
                            title: 'Árboles por distritos',
                        }}
                        rootProps={{ 'data-testid': '3' }}
                    />
                    <table style={{ borderCollapse: 'collapse', width: '50%', height: '300px' }}>
                        <thead>
                            <tr>
                                <th style={tableHeaderStyle}>Distrito</th>
                                <th style={tableHeaderStyle}>Árboles</th>
                            </tr>
                        </thead>
                        <tbody>
                            {treeChartData.slice(1).map(([tree, count]) => (
                                <tr key={tree}>
                                    <td style={tableCellStyle}>{tree}</td>
                                    <td style={tableCellStyle}>{count}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div>
                    
                    <p>Total Árboles: {totalTrees}</p>
                </div>
            </div>
        </div>
    );
};

export default Estadisticas;






