import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import logo from './../Components/LOGO2.png';
import { useAuth } from '../Components/Authentication';

const apiUrl = process.env.REACT_APP_API_URL;
const debugMode = process.env.REACT_APP_DEBUG_MODE === 'true';

const Header = () => {
  const { logout } = useAuth();

  const handleLogout = () => {
      logout();
      window.location.href = '/login';
  }

  return (
      <div className="dashboard-header">
          <button className="menu-icon">
              ☰
          </button>
          <img src={logo} alt="Logo" className="header-logo" />
          <span>IDENTIARBOL</span>
          <button onClick={handleLogout}> 🚪</button>
      </div>
  );
}
const RegistrosArboles = () => {
  const { id } = useParams(); 
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl+`owner/${id}`);
        const result = await response.json();
        
        // Aquí, añadimos el campo contador a cada elemento del arreglo
        const dataWithCounter = (result.owner || []).map((item, index) => ({
          ...item,
          contador: index + 1
        }));
        
        setData(dataWithCounter);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [id]);

  const columns = [
    { name: 'Código de árbol', selector: 'reg_txt_identifier', sortable: true },
    { name: 'Nombre común', selector: 'reg_txt_commonnames', sortable: true },
    { name: 'Nombre científico', selector: 'reg_txt_scientificname', sortable: true },
    { name: 'Usos de la especie', selector: 'reg_txt_applications', sortable: true },
    {
      name: 'Acción',
      sortable: false,
      cell: row => (
          <a 
              href={`/ficha-arbol/${row.reg_int_id}`}
              target="_blank" 
              rel="noopener noreferrer"
          >
              <button>
                  Ver Ficha
              </button>
          </a>
      ),
  },
    // Agrega aquí el resto de las columnas que desees mostrar
  ];

  return (
    <div>
      <Header />
      <h1>Vista de Permisos</h1>
      <p>ID del Árbol: {id}</p>
      <DataTable
        title="Datos del Árbol"
        columns={columns}
        data={data}
      />
    </div>
  );
};

export default RegistrosArboles;
