import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Header from './Components/Header';
import { Typeahead } from 'react-bootstrap-typeahead';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const apiUrl = process.env.REACT_APP_API_URL;
const debugMode = process.env.REACT_APP_DEBUG_MODE === 'true';

const Buscador = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchTerm = queryParams.get('query');

  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [tableSearchTerm, setTableSearchTerm] = useState(searchTerm || '');

  const fetchResults = useCallback(async (searchTerm, allTreesData) => {
    setLoading(true);
    try {
      const trees = allTreesData || (await axios.get(apiUrl + 'alltrees')).data.registered;
      const lowerCaseSearchTerm = searchTerm.toLowerCase();

      const filteredTrees = trees.filter(
        (tree) =>
          tree.reg_txt_identifier?.toLowerCase().includes(lowerCaseSearchTerm) ||
          tree.reg_txt_scientificname?.toLowerCase().includes(lowerCaseSearchTerm) ||
          tree.reg_txt_commonnames?.toLowerCase().includes(lowerCaseSearchTerm)
      );

      setResults(filteredTrees);
    } catch (error) {
      console.error('Error fetching search results', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(apiUrl + 'alltrees');
        setData(response.data);
        if (searchTerm) {
          fetchResults(searchTerm, response.data.registered);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    }
    fetchData();
  }, [searchTerm, fetchResults]);

  const handleSearchClick = () => {
    if (tableSearchTerm.trim()) {
      fetchResults(tableSearchTerm, data?.registered);
    }
  };


    const columns = [
        { name: 'Código de árbol', selector: 'reg_txt_identifier', sortable: true },
        { name: 'Nombre común', selector: 'reg_txt_commonnames', sortable: true },
        { name: 'Nombre científico', selector: 'reg_txt_scientificname', sortable: true },
        {
            name: 'Acción',
            sortable: false,
            cell: row => (
                <a 
                    href={`/ficha-arbol/${row.reg_int_id}`}
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    <button
                                                                className="btn btn-success"
                                                                style={{ 
                                                                    padding: '10px 20px', // Añade espacio a la izquierda y derecha del texto del botón
                                                                    borderRadius: '8px', // Borde redondeado solo en la derecha
                                                                }}
                    
                    >
                        Ver Ficha
                    </button>
                </a>
            ),
        },
    ];

    if (!data) return <p>Cargando...</p>;

    return (
        <>
            <Header />
            <div style={{ padding: '20px' }}>
                <h5><b>Buscador de Fichas electrónicas:</b></h5>
                <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Typeahead
                        id="buscador"
                        labelKey={(option) => `${option.reg_txt_identifier} - ${option.reg_txt_commonnames} - ${option.reg_txt_scientificname}`}
                        options={data.registered}
                        placeholder="Buscar por identificador, nombre común o nombre científico"
                        minLength={1}
                        style={{ width: '70%' }}
                        onChange={(selected) => {
                            if (selected.length > 0) {
                                const url = `/ficha-arbol/${selected[0].reg_int_id}`;
                                window.open(url, '_blank');
                            }
                        }}
                    />
                </div>
                <h5><b>Buscador general de arboles:</b></h5>
                <div style={{ marginBottom: '20px', display: 'flex',width: '100%' }}>
                <FormControl
                    id="table-buscador"
                    type="text"
                    placeholder="Escriba su búsqueda y haga clic en Buscar"
                    value={tableSearchTerm}
                    onChange={(e) => setTableSearchTerm(e.target.value)}
                    style={{ width: '50%' }}
                />

                    <Button variant="success" onClick={handleSearchClick} style={{marginLeft:'10px',width:'70px' }}>Buscar</Button>
                </div>

                <p>{results.length} coincidencias encontradas para {tableSearchTerm}</p>
                {loading ? (
                    <p>Cargando resultados...</p>
                ) : (
                    <DataTable columns={columns} data={results} pagination highlightOnHover />
                )}
            </div>
        </>
    );
};

export default Buscador;
