import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Components/Header';
import VisitorInfo from './Info';

const Bases = () => {
    return (
        <>
            <Header />
            <VisitorInfo page="BASES"></VisitorInfo>
            <iframe
                title="Bases Identiarbol"
                src="https://identiarbol.org/bases.html"
                style={{
                    width: '100%',
                    height: '1000px', // Ajusta la altura según tus necesidades
                    border: 'none', // Elimina el borde del iframe si lo deseas
                }}
            ></iframe>
        </>
    );
}

export default Bases;
