import React, { useState } from 'react';

const Albumconcursante = () => {
  const [dni, setDni] = useState('');
  const [concursanteDatos, setConcursanteDatos] = useState(null);

  const buscarConcursante = () => {
    // Aquí deberías realizar la lógica para buscar y obtener los datos del concursante por el DNI
    // Por ahora, utilizaremos datos de ejemplo
    const datosEjemplo = {
      album: ["imagen1.jpg", "imagen2.jpg", "imagen3.jpg"],
      arboles: [
        { id: 1, imagen: "arbol1.jpg" },
        { id: 2, imagen: "arbol2.jpg" },
        { id: 3, imagen: "arbol3.jpg" },
        { id: 4, imagen: "arbol4.jpg" },
        { id: 5, imagen: "arbol5.jpg" }
      ]
    };
    setConcursanteDatos(datosEjemplo);
  };

  const visualizarArbol = (arbolId) => {
    // Aquí deberías implementar la lógica para mostrar el árbol completo según el ID
    alert("Visualizando árbol con ID " + arbolId);
  };

  return (
    <div>
      <h1>Álbum de Concursantes</h1>

      <label htmlFor="dniInput">Buscar por DNI:</label>
      <input
        type="text"
        id="dniInput"
        name="dniInput"
        value={dni}
        onChange={(e) => setDni(e.target.value)}
        required
      />
      <button type="button" onClick={buscarConcursante}>
        Buscar
      </button>

      {concursanteDatos && (
        <div>
          <h2>Álbum del Concursante</h2>
          <div id="concursanteAlbum">
            {concursanteDatos.album.map((imagen, index) => (
              <img key={index} src={imagen} className="tree-image" alt={`Imagen ${index + 1}`} />
            ))}
          </div>

          <h2>Lista de Árboles Genealógicos</h2>
          <ul className="tree-list">
            {concursanteDatos.arboles.slice(0, 5).map((tree) => (
              <li key={tree.id} className="tree-list-item">
                <img src={tree.imagen} className="tree-image" alt={`Árbol ${tree.id}`} />
                <button onClick={() => visualizarArbol(tree.id)}>Visualizar</button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Albumconcursante;

