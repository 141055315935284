import DataTable from 'react-data-table-component';
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polygon } from 'react-leaflet';
import axios from 'axios';
import Header from './Components/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';
import L, { Icon } from 'leaflet';
import Iconomap from './Components/img/marcadorrojo.png';
import './Source/visor.css';

const center = {
  lat: -7.215344, 
  lng: -74.854994,
};

const treeIcon = new Icon({
  iconUrl: Iconomap,
  iconSize: [35, 41],
  iconAnchor: [12, 41],
});

const districtColors = [

    '#30C030',

  ];

const VisorPeru = () => {
  const [trees, setTrees] = useState([]);
  const [search, setSearch] = useState('');
  const [coordinates, setCoordinates] = useState([]);
  const [geojsonData, setgeojsonData] = useState([]);

  const [selectedDepartment, setSelectedDepartment] = useState('TODO');
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);

  const [departments, setDepartments] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);

  const [isDeptDisabled, setIsDeptDisabled] = useState(false);
const [isProvDisabled, setIsProvDisabled] = useState(false);


useEffect(() => {
  axios
    .get('https://identiarbol.org/PERU.json')
    .then((response) => {
      
      const geojsonData = response.data;
      setgeojsonData(geojsonData);
      if (geojsonData && geojsonData.features) {
        const uniqueDepartments = ['TODO'].concat(
          Array.from(
            new Set(
              geojsonData.features.map((f) => f.properties.NOMBDEP)
            )
          )
        );
        setDepartments(uniqueDepartments.sort());
      }
    })
    .catch((error) => {
      console.error('Error al cargar el archivo JSON desde la URL.', error);
    });
}, []);



    useEffect(() => {
        if (selectedDepartment && selectedDepartment !== 'TODO') {
            const uniqueProvinces = Array.from(new Set(geojsonData.features.filter(f => f.properties.NOMBDEP === selectedDepartment).map(f => f.properties.NOMBPROV)));
            setProvinces(uniqueProvinces.sort());
        } else {
            setProvinces([]);
        }
    }, [selectedDepartment]);

    useEffect(() => {
        if (selectedProvince) {
            const uniqueDistricts = Array.from(new Set(geojsonData.features.filter(f => f.properties.NOMBPROV === selectedProvince).map(f => f.properties.NOMBDIST)));
            setDistricts(uniqueDistricts.sort());
        } else {
            setDistricts([]);
        }
    }, [selectedProvince]);


  useEffect(() => {
    if (geojsonData.features && Array.isArray(geojsonData.features) && geojsonData.features.length > 0) {
      const allPolygons = geojsonData.features.filter(feature => feature.geometry && feature.geometry.type === 'MultiPolygon')
          .map(feature => feature.geometry.coordinates);
      if (Array.isArray(allPolygons) && allPolygons.length > 0) {
          setCoordinates(allPolygons);
      } else {
          setCoordinates([]);
      }
    } else {
      setCoordinates([]);
    }
  }, []);

  const handleSelectDepartment = (dept) => {
    setSelectedDepartment(dept === 'TODO' ? null : dept);
    setSelectedProvince(null);
    setSelectedDistrict(null);
};

const handleSelectProvince = (prov) => {
    // Añadir esta línea
    setSelectedProvince(prov);
    setSelectedDistrict(null);
};

const handleSelectDistrict = (dist) => {
     // Añadir esta línea
    setSelectedDistrict(dist);
};




  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const filteredTrees = search
    ? trees.filter(tree => 
        tree.reg_txt_scientificname?.toLowerCase().includes(search.toLowerCase()) ||
        tree.reg_txt_commonnames?.toLowerCase().includes(search.toLowerCase()) ||
        tree.reg_txt_identifier?.toLowerCase().includes(search.toLowerCase())
      )
    : trees;



  return (
    <>
      <Header />
      <div className="d-flex flex-column align-items-center">        
        <MapContainer center={center} zoom={5} style={{ width: '100%', height: '650px' }}>
        <div className="control" style={{ display: 'flex', gap: '20px' }}>
    
        {/* Departamentos */}
        <div>
            <label htmlFor="departmentsSelect">Departamentos: </label>
            <select 
                id="departmentsSelect" 
                value={selectedDepartment}
                onChange={e => handleSelectDepartment(e.target.value)}>
                {departments.map(dept => (
                    <option key={dept} value={dept}>
                        {dept}
                    </option>
                ))}
            </select>
        </div>

        {/* Provincias */}
        <div>
            <label htmlFor="provincesSelect">Provincias: </label>
            <select 
                id="provincesSelect" 
                value={selectedProvince || ""}
                onChange={e => handleSelectProvince(e.target.value)}
                disabled={!selectedDepartment || selectedDepartment === 'TODO'}>
                <option value="">Seleccione una provincia</option>
                {provinces.map(prov => (
                    <option key={prov} value={prov}>
                        {prov}
                    </option>
                ))}
            </select>
        </div>

        {/* Distritos */}
        <div>
            <label htmlFor="districtsSelect">Distritos: </label>
            <select 
                id="districtsSelect" 
                value={selectedDistrict || ""}
                onChange={e => handleSelectDistrict(e.target.value)}
                disabled={!selectedProvince}>
                <option value="">Seleccione un distrito</option>
                {districts.map(dist => (
                    <option key={dist} value={dist}>
                        {dist}
                    </option>
                ))}
            </select>
        </div>

</div>



          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            attribution='&copy; <a href="https://www.esri.com/">Esri</a>'
          />



        {Array.isArray(coordinates) && coordinates.map((multiPolygon, multiIndex) => 
            (
                (selectedDepartment === 'TODO' || geojsonData.features[multiIndex].properties.NOMBDEP === selectedDepartment) &&
                (!selectedProvince || geojsonData.features[multiIndex].properties.NOMBPROV === selectedProvince) &&
                (!selectedDistrict || geojsonData.features[multiIndex].properties.NOMBDIST === selectedDistrict)
            ) &&
            Array.isArray(multiPolygon) && 
            multiPolygon.map((polygon, polyIndex) => 
                Array.isArray(polygon) && 
                <Polygon 
                    key={`${multiIndex}-${polyIndex}`} 
                    positions={polygon[0].map(([lng, lat]) => [lat, lng])}
                    color={districtColors[multiIndex % districtColors.length]}
                />
            )
        )}



        </MapContainer>


      </div>
    </>
  );
};

export default VisorPeru;
